* {
  scroll-behavior: smooth;
}

.main-container {
  margin: auto;
}

.title {
  font-size: 20px;
  line-height: 100%;
  color: black;
  font-weight: bold;
}

.link3 {
  text-decoration: unset;
  color: black;
}


.main-name {
  font-size: 65px;
  line-height: 100%;
  color: black;
  font-weight: 600;
  margin-top: 5px;
  text-align: center;
}

/* .link3:hover .mr-copy {
  color: white;
} */

.scroll-container {
  margin-bottom: 54%;
}

.bg-gray {
  width: 90%;
  margin: auto;
  margin-bottom: 76px;
}

/* .section-2 {
  background-color: #1a2a36;
}

.section-3 {
  background-color: #1a2a36;
} */

.s2-title {
  color: white;
  font-size: 21px;
  line-height: 23px;
  font-weight: 400;
}

.num-projects {
  color: white;
  font-weight: bold;
}

.nav-title {
  margin-bottom: unset;
}

.nav-title-middle {
  margin-bottom: unset;
  margin-left: 20px;
  margin-right: 20px;
}

.img-scroller {
  display: flex;
  justify-content: space-between;
  max-width: 1800px;
  margin: auto;
}

.img-scroller img {
  margin: 10px;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 12px;
}

.img-wrapper {
  --scrolling-gallery-item-duration: 25s;
  --scrolling-gallery-items-visible: 7;
  --scrolling-gallery-items-total: 7;
  overflow: hidden;
  will-change: transform;
}

.scroller {
  animation-duration: calc(var(--scrolling-gallery-item-duration, 1s) / var(--scrolling-gallery-items-visible) * var(--scrolling-gallery-items-total));
  animation-timing-function: linear;
  animation-name: scrolling-gallery;
  animation-iteration-count: infinite;
  display: flex;
  white-space: nowrap;
}

.scroller img {
  /* flex: 1 0 calc(100% / var(--scrolling-gallery-items-visible));
  width: 200px; */
  height: 610px;
}

@keyframes scrolling-gallery {
  0% {
      transform: translateX(0);
  }

  100% {
      transform: translateX(calc(var(--scrolling-gallery-items-total) * -100vw / var(--scrolling-gallery-items-visible)));
  }
}

.scroller:hover,
.scroller:focus {
  animation-play-state: paused;
}




.nav {
  display: flex;
    justify-content: space-between;
    max-width: 641px;
    margin: auto;
    background-color: #f4f4f4;
    padding: 1px 17px 0px 17px;
    margin-top: 15px;
    border-radius: 100px;
    position: 'sticky';
    top: 10;
    z-index: 100;
}
.inner-footer {
  width: 90%;
  margin: auto;
}

.s2-job-title {
  color: white;
  font-size: 26px;
  line-height: 29px;
  font-weight: 400;
}

.s2-job-title-black {
  color: black;
  font-size: 26px;
  line-height: 29px;
  font-weight: 400;
}

.fixx img {
  margin-right: 20px;
}

.role-copy {
  color: white;
  max-width: 500px;
  font-size: 21px;
  line-height: 29px;
}

.role-copy-black {
  color: black;
  max-width: 500px;
  font-size: 21px;
  line-height: 29px;
}

.inner-section {
  width: 90%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.s2-row {
  display: flex;
  justify-content: space-between;
}

.s2-col-left {
  width: 60%;
  margin-right: 50px;
}



.jp-img {
  height: 340px;
  border-radius: 6px;
  margin-bottom: 50px;
}

.vid-css {
  height: 340px;
  margin: auto;
  display: block;
}

.s3-row {
  display: flex;
}

.s3-row img {
  height: 400px;
    width: 33%;
    object-fit: contain;
    border-radius: 4px;
}

.mob-img-scroll {
  margin-right: 15px;
  border-radius: 12px;
  height: 300px;
}


.hr-line {
  margin-top: 60px;
  border: .5px solid gray;
}

/* .section-3 {
  margin-top: 80px;
} */

.top-main-icons-row {
  display: none;
    justify-content: space-between;
    max-width: 125px;
    margin-top: 60px;
    align-items: center;
}

#Jordans {
  padding: 30px;
  background-color: #564374;
    border-radius: 24px;
}

.dload_btn {
  background-color: #252de0;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dload_text {
  margin-top: unset;
  margin-bottom: unset;
  color: white;
}

.s-last-h1 {
  text-align: center;
    font-size: 43px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: unset;
}
/* 
#firstt {
  margin-left: -15%;
} */

/* .scroll-container {
  height: 100vh;
} */

.carousel-primary,
.carousel-secondary {
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.carousel-primary img {
  width: 100%;
  margin-left: 20px;
  border-radius: 14px;
  height: 600px;
}

.main-copy-me {
  text-align: center;
  font-size: 17px;
}

.carousel-primary {
  display: flex;
  justify-content: space-around;
  animation: scroll-horizontal 20s linear infinite;
}

.carousel-secondary {
  animation: scroll-horizontal 20s linear infinite;
  animation-delay: 10s;
}

@keyframes scroll-horizontal {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.carousel-primary:hover,
.carousel-primary:hover~.carousel-secondary {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.carousel-secondary:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}


.scroll-container:hover>.carousel-primary:not(:hover) {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.dload_btns {
  display: flex;
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 20px;
}

.role-copy-center {
  color: black;
  max-width: unset;
  width: 87%;
  line-height: 1.5;
  text-align: center;
  margin: auto;
  font-size: 21px;
  margin-top: 40px;
  margin-bottom: 40px;
}


#Bonjour {
  padding: 30px;
  background-color: #dfdfdf;
    border-radius: 24px;
    margin-top: 60px;
}

#propertio {
  padding: 30px;
  background-color: #bcbcbc;
  border-radius: 24px;
  margin-top: 60px;
  width: 90%;
  margin: auto;
}




.tmir-col {
  background-color: gray;
  padding: 15px;
  border-radius: 50px;
}

.me-logo {
  height: 200px;
  border-radius: 200px;
  border: 3px solid transparent;
  display: block;
  margin: auto;
}


.tmir-icon {
  font-size: 24px;
  color: white;
}

.me-logo-row {
  display: block;
  align-items: center;
  margin: auto;
}

.main-links-row {
  display: flex;
      justify-content: center;
      align-items: center;
}

.me-logo:hover {
  border: 3px solid white;
}

.ft-btn-row {
  display: flex;
  justify-content: space-between;
  max-width: 291px;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 50px;
}

.ft-btn {
  background-color: gray;
  padding: 10px;
  border-radius: 4px;
}

.main-home-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-left {
  width: 100%;
  display: block;
  margin-top: 55px;
}


.main-right {
  width: 30%;
  display: none;
}

.mr-line {
  width: 90%;
  margin: auto;
  margin-left: unset;
  border: unset !important;
  background-color: #c4c4c4;
  height: 2px;
}

.mr-copy {
  margin-top: 30px;
  font-size: 20px;
}

.mr-text {
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: bold;
}


.td-mark {
  margin-bottom: 25px;
  color: white;
  font-weight: 600;
}

.ft-btn-copy {
  margin-top: unset;
  margin-bottom: unset;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  display: flex;
  align-items: center;
}

.icon {
  font-size: 30px;
}

.icon-row-nav {
  display: flex; 
}

.sep {
  width: 15px;
  background-color: transparent;
  height: 100%;
}

.sep-2 {
  margin-left: 10px;
  margin-right: 10px;;
}

.icon-row {
  display: flex;
    justify-content: space-between;
}

.icon-col-left {
  margin-right: 30px;
}


.ft-outer-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg-btn {
  background-color: gray;
  max-width: 150px;
  padding: 11px;
  border-radius: 4px;
  margin-top: 40px;
}

.msg-btn-copy {
  margin-top: unset;
  margin-bottom: unset;
  color: white;
  font-weight: bold;
  font-size: 18px;
}





/* html#bg-gray {
  background-color: #d1ddff;
} */

p.main-copy {
  max-width: 738px;
  font-size: 23px;
  line-height: 35px;
}

.link {
  text-decoration-color: black;
}

.link-nav {
  text-decoration: unset;
  color: black;
  /* font-weight: bold; */
}

.assets-col {
  margin: auto;
  display: block;
  width: 55%;
}


.link2 {
  text-decoration: unset;
}

.mx150 {
  max-width: 180px;
  margin: auto;
  margin-left: unset;
}

.black-icon {
  color: white;
  font-weight: 600;
}

.inner-pos-col {
  position: -webkit-sticky; /* for browser compatibility */
  position: sticky;
  top: 10px;
}


.li-link {
  text-decoration: none;
}

.li-icon {
  color: black;
}


#show_txt {
  display: none;
}

.mob-img-scroll-container {
  display: none;
}

@media (max-width: 767px) {
  .main-home-row {
    display: block;
  }

  .mob-img-scroll-container {
    display: flex;
        justify-content: space-between;
        margin: auto;
        overflow: scroll;
  }
  

  .role-copy-black {
    font-size: 18px;
    line-height: 23px;
  }

  .role-copy {
    font-size: 18px;
    line-height: 23px;
  }

  .nav-title {
    margin-bottom: unset;
    font-size: 15px;
}

.nav-title-middle {
  margin-bottom: unset;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
}

.title {
  font-size: 18px;
  line-height: 100%;
  color: black;
  font-weight: bold;
}

  .img-wrapper {
    display: none;
  }

  #show_txt {
    display: block;
    text-align: center;
    color: #356b1b;
  }

  .role-copy-center {
    color: black;
    max-width: unset;
    width: unset;
    line-height: 1.5;
    text-align: center;
    margin: auto;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #propertio {
    width: unset;
  }
  .mr-text {
    color: white;
  }

  .top-main-icons-row {
    display: none;
  }

  .main-left {
    width: unset;
  }

  .main-right {
    width: unset;
  }

  .msg-btn {
    max-width: unset;
    width: unset;
    margin-bottom: 60px;
    margin-top: 51px;
  }

  .msg-btn-copy {
    text-align: center;
  }

  .main-name {
    width: unset;
    margin-bottom: 8px;
    font-size: 62px;
    font-weight: 600;
  }

  .ft-outer-row {
    display: unset;
  }

  .icon-row {
    max-width: 145px;
  }

}

@media (max-width: 991px) {
  .assets-col {
    margin: auto !important;
    display: flex !important;
    width: 95% !important;
    align-items: center !important;
    overflow-x: auto !important;
    margin-left: unset !important;
  }

  .s2-row {
    display: block;
  }

  .role-copy {
    max-width: unset;
  }
  .s2-col-left {
    width: 90%;
  }
}

.linkcta {
  color: white;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 600;
}

.linkcta-black {
  color: black;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 600;
}


@media (max-width: 1306px) {
  .assets-col {
    margin: auto;
    display: flex;
    width: 55%;
    align-items: center;
    overflow-x: auto;
}

.s3-row img {
  width: unset;
  height: 338px;
  margin-right: 31px;
}

.assets-col::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

.assets-col::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px red;  */
  border-radius: 10px;
  background-color: #f4f4f4;
}

.assets-col::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px green;  */
  background-color: gray;
}

.jp-img {
  height: 340px;
  border-radius: 6px;
  margin-bottom: unset;
  margin-right: 30px;
}
}


@media (min-width: 1680px) {
  .jp-img {
    height: 495px !important;
    border-radius: 6px;
  }
}

